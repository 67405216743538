import { useState } from "react"

//Utils
import {
  login_link,
  register_link,
  update_password_link,
} from "components/links"
import { Form, Formik } from "formik"
import { AsyncApollo, M_LOGIN } from "lib/functions"
import { useRouter } from "next/router"
import * as Yup from "yup"

import Cookies from "js-cookie"

//Assets
import logoGoogle from "assets/img/common/logo_google.png"
import coverImg from "assets/img/login/cover.jpg"

//Components
import logoStroke from "assets/ico/icon_stroke.png"
import { Button, Cover, Input } from "components/anti"
import ModalForgotPassword from "components/global/modal-forgot-password"
import { useScrollAnim } from "components/hooks/hooks"
import GoogleLoginSuggestModal from "components/modal/google-login-suggest"
import { dataLayerEvent } from "lib/functions/datalayer-v2"
import { toastHandling } from "lib/utils/toast-handling"
import { setCookie } from "nookies"

const SignInComponents = ({
  loginLink = login_link,
  registerLink = register_link,
  checkout,
}) => {
  const forgotModalId = "forgot-password"
  const googleSuggestId = "suggest-google-id-modal"

  //Utils ************************************************************************************* //
  const [ref, anim] = useScrollAnim()
  const Router = useRouter()
  const YupLoginValidation = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email required"),
    password: Yup.string().required("Password required"),
  })
  //Utils ************************************************************************************* //

  //States ************************************************************************************* //
  let loginInitialStates = {
    email: "",
    password: "",
  }
  const [state, setState] = useState({
    btnSuccess: false,
    showModalForgot: null,
    btnloading: null,
    showSuggest: null,
  })
  //States ************************************************************************************* //

  //Functions ********************************************************************************** //
  const handleSubmit = async (values) => {
    // setState((prev) => ({ ...prev, btnloading: true }))
    let { email, password } = values
    let { data, errors } = await AsyncApollo({
      mutation: M_LOGIN,
      variables: { username: email, password },
    })
    if (data) {
      if (data?.login?.status === "ERROR_SOCIAL") {
        setState((prev) => ({ ...prev, showSuggest: googleSuggestId }))
      } else if (data?.login?.status === "NEED_RESET") {
        if (checkout) Cookies.set("is_reset_pass_checkout", true)
        Router.push(
          {
            pathname: `${update_password_link}`,
            query: { email },
          },
          `${update_password_link}`
        )
      } else if (data?.login?.status === "ERROR") {
        toastHandling({
          status: "danger",
          message: "Invalid email / password",
        })
      } else {
        let { access_token } = data?.login
        dataLayerEvent.loginRegister("login", "email")
        Cookies.set(`${process.env.ACCESS_TOKEN}`, access_token, {
          expires: 7,
        })
        window.location.reload()
        setCookie(null, "show_toast_verify", true, {
          maxAge: 30 * 24 * 60 * 60,
          path: "/",
        })
      }
    } else {
      //!NEED ERROR HANDLING
      // setState((prev) => ({ ...prev, btnloading: false }))
      if (errors[0]?.message == "Internal server error") {
        toastHandling({
          status: "danger",
          message: "Internal Server Error",
          // message: errors[0]?.extensions?.reason || errors[0]?.message,
        })
      } else {
        toastHandling({
          status: "danger",
          message: "Invalid email / password",
          // message: errors[0]?.extensions?.reason || errors[0]?.message,
        })
      }
    }
  }
  //Functions ********************************************************************************** //

  //Component ********************************************************************************** //
  const LoginForm = () => {
    return (
      <div id="login" className="form-body form-register anim fadeInUp d1">
        <GoogleLoginSuggestModal
          id={googleSuggestId}
          show={state.showSuggest}
          hide={() => setState((prev) => ({ ...prev, showSuggest: null }))}
        />

        <a
          href={`/api/google${checkout ? "?location=checkout" : ""}`}
          className="btn btn-outline-primary btn-google w-100 mb-3"
          onClick={() => dataLayerEvent.loginRegister("login", "google")}
        >
          <img src={logoGoogle} alt="logo-google" className="img-fluid mr-2" />
          Continue with Google
        </a>

        <p className="text-center py-2">or login with email below</p>
        <Formik
          initialValues={loginInitialStates}
          validationSchema={YupLoginValidation}
          onSubmit={handleSubmit}
        >
          {({ handleChange, errors, touched, values, isSubmitting }) => (
            <Form>
              <div className="form-row">
                <div className="form-group col-12">
                  <Input
                    floatingLabel
                    label="Email"
                    as="input"
                    type="email"
                    id="email"
                    name="email"
                    value={values["email"]}
                    onChange={handleChange}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="form-group col-12">
                  <Input
                    floatingLabel
                    className="field-password"
                    label="Password"
                    as="input"
                    type={"password"}
                    id="password"
                    name="password"
                    value={values["password"]}
                    onChange={handleChange}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="form-group col-12">
                  <div
                    className="btn btn-forgot-password float-right"
                    type="button"
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        showModalForgot: forgotModalId,
                      }))
                    }
                  >
                    Forgot password?
                  </div>
                </div>

                <div className="col-12">
                  <Button
                    loader
                    variant="dark"
                    className={`btn-block ${isSubmitting && "loading"}`}
                    type="submit"
                  >
                    {state.btnSuccess ? (
                      <i className="ail ai-check" />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
  //Component ********************************************************************************** //

  return (
    <section className="sc-auth login" ref={ref}>
      <ModalForgotPassword
        id={forgotModalId}
        show={state.showModalForgot}
        hide={() => setState((prev) => ({ ...prev, showModalForgot: null }))}
        isCheckout={checkout}
      />
      <div className="container">
        <div className="form-wrapper">
          <div className="form-nav">
            <Button variant="link" onClick={() => Router.push(registerLink)}>
              Register
            </Button>
            <img src={logoStroke} alt="divider" />
            <Button
              variant="link"
              className="active"
              onClick={() => Router.push(loginLink)}
            >
              Login
            </Button>
          </div>
          {LoginForm()}
        </div>
      </div>
      <Cover img={coverImg} imgOverlay={30} />

      {/* <div className="sc-auth-wrapper-mobile">
        <div className="form-wrapper">
          <div className="form-nav">
            <div className="wrapper-button">
              <Button
                variant="login"
                className={`btn-block h2`}
                onClick={() => Router.push(registerLink)}
              >
                Register
              </Button>
            </div>
            <Image src={logoStroke} alt="divider" className="img-divider" />
            <div className="wrapper-button">
              <Button
                variant="login"
                className={`btn-block h2 active`}
                onClick={() => Router.push(loginLink)}
              >
                Login
              </Button>
            </div>
          </div>
          {LoginForm()}
        </div>
      </div> */}
    </section>
  )
}

export default SignInComponents
