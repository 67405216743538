import React from "react"

//Components
import { Modal, Button, Input } from "components/anti"

//Utils
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { M_FORGOT_PASSWORD } from "lib/graphql"
import { AsyncApollo } from "lib/functions"
import { toastHandling } from "lib/utils/toast-handling"
import Cookies from "js-cookie"

const ModalForgotPassword = ({ id, show, hide, isCheckout }) => {
  //Utils
  const YupForgotPassword = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email required"),
  })

  //States
  const initialState = {
    email: "",
  }

  const [state, setState] = React.useState({
    btnLoading: false,
  })

  const handleSubmit = async (values) => {
    setState((prev) => ({ ...prev, btnLoading: true }))
    let { data, errors } = await AsyncApollo({
      mutation: M_FORGOT_PASSWORD,
      variables: { email: values.email },
    })
    if (data?.forgotPassword) {
      let { status, message } = data.forgotPassword
      if (status === "EMAIL_NOT_SENT") {
        toastHandling({
          message,
          status: "danger",
        })
        setState((prev) => ({ ...prev, btnLoading: false }))
        hide()
      } else if (status === "EMAIL_SENT") {
        setState((prev) => ({ ...prev, btnLoading: false }))
        toastHandling({
          message: "We have emailed you a link to update your password",
          status: "success",
        })
        if (isCheckout) {
          Cookies.set("is_reset_pass_checkout", true)
        }
        hide()
      }
    } else {
      toastHandling({
        type: "general_error",
      })
    }
  }

  return (
    <Modal
      id={id}
      isShowing={show}
      hide={hide}
      title="Reset Password"
      className="centered"
    >
      <section>
        <p>Enter your email below to reset your password.</p>
        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
          validationSchema={YupForgotPassword}
        >
          {({ handleChange, errors, touched, values, isSubmitting }) => (
            <Form>
              <div className="form-row">
                <div className="form-group col-12">
                  <Input
                    formik
                    floatingLabel
                    variant="box"
                    label="Email"
                    as="input"
                    type="email"
                    id="email"
                    name="email"
                    value={values["email"]}
                    onChange={handleChange}
                    touched={touched}
                    errors={errors}
                  />
                </div>
                <div className="form-group mb-0 col-12">
                  <Button
                    loader
                    variant="primary"
                    className={`btn-block ${state.btnLoading && "loading"}`}
                    type="submit"
                  >
                    {/* {isSent ? <i className='air ai-check'></i> : "Send Reset Link"} */}
                    Send Reset Link
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </Modal>
  )
}

export default ModalForgotPassword
