import React from "react"
import { Modal, Button } from "components/anti"
import logoGoogle from "assets/img/common/logo_google.svg"

const GoogleLoginSuggestModal = ({ id, show, hide, children }) => {
  return (
    <Modal
      id={id}
      isShowing={show}
      hide={hide}
      className="modal-sm centered my-2"
    >
      <h5>You have logged in with Google account before</h5>
      <div className="mt-4">
        <a
          href="/api/google"
          className="btn btn-outline-primary btn-google w-100"
        >
          <img src={logoGoogle} alt="logo-google" className="img-fluid mr-2" />
          Continue with Google
        </a>
      </div>
      <p className="text-center my-2 mb-1 p-0">or</p>
      <div>
        <Button className="w-100" onClick={() => hide()}>
          use other email
        </Button>
      </div>
    </Modal>
  )
}

export default GoogleLoginSuggestModal

// .google-login-box {
//   padding: 1rem;
//   border: 1px solid $dark;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 1rem;
//   box-shadow: $box-shadow-sm;
//   cursor: pointer;
// }
